import {
  checkAccountOpeningProgress,
  isAdmin
} from "../api/biz.ts";
import {SuccessCode} from "../constants/env.ts";
// import { AccountOpeningProgressEnum } from "../constants/biz.ts";
import {modal} from "../utils/tipModal.ts";
import {goTo} from "../utils/location.ts";
import { BusinessApiCode } from "../constants/enums.ts";
import { tr } from "../i18n.ts";
import { historyBack, historyPush } from "../routes/router-main.ts";
import { AccountOpeningInterceptorRoutes } from "../routes/rules/main.ts";
import { queryCompanyInfo } from "../api/assets.ts";
import { checkIsRealNamed, checkIsPWDSetted } from '../hooks/useUserStatus.ts';
import { OnWayApplyStatusEnum, RealStatusEnum } from "../constants/biz.ts";

export async function showIsAdmin(_to: any, from: any) {
  try {
    const ret = await isAdmin()
    if (ret.code === BusinessApiCode.SUCCESS && ret.data) {
      return true;
    } else {
      modal.error({
        title: tr('accountOpen.admin.title'),
        description: tr('accountOpen.admin.desc'),
        okText: tr('accountOpen.admin.login'),
        onOk: () => {
          const loginUrl = encodeURIComponent(import.meta.env.VITE_LOGIN_URL) + '?return=' + encodeURIComponent(window.location.href)
          goTo(`//passport.jdpay.com/user/exit.do?loginUrl=${loginUrl}`)
        }
      })
      return false;
    }
  } catch (e) {
    return false;
  }
}

/**
 * 检查该路由是否在开户校验拦截路由范围内
 * @param route 路由对象
 * @return true 在拦截范围内 false 不在拦截范围内
 */
function checkInInterceptorRoutes(route: any) {
  return route && route.fullPath && AccountOpeningInterceptorRoutes.indexOf(route.name) > -1 
}

/**
 * 
 * @param routeInfo : { to, from } 路由信息  1.存在，说明是在路由拦截那用到该方法 2.不存在 目的使用该方法的返回值用于权限验证
 * @param cancelTopJump 点击取消是否有跳转链接动作； 有的地方不需要跳转只需要弹窗消失
 * @returns boolean false 会有tip提示 true 不会有tip提示， 路由守卫那里也会用到
 */
export async function showAccountOpeningModal(routeInfo?: { to: any, from: any }, cancelTopJump: boolean = true) {
  
  try {
    const ret = await checkAccountOpeningProgress()
    if (ret.code === SuccessCode) {
      const status = ret.data;
      // 未实名
      if (!checkIsRealNamed(status)) {
        let realnameStatus;
        try {
          realnameStatus = await queryCompanyInfo();
          if (realnameStatus.code === "PTS700006") { //未实名
            realnameStatus = null;
          }
        } catch (e) {
          realnameStatus = null;
        }
        const { from, to } = routeInfo || {};
        const fromPath = (!from || checkInInterceptorRoutes(from)) ? '/' : from.fullPath;
        if (routeInfo && !checkInInterceptorRoutes(to)) return false; // 存在路由信息,再拦截路由那里用到该方法并且最新的路由没在拦截路由范围内就不用弹出框了
        if (realnameStatus && 
            ((realnameStatus.data.realStatus === RealStatusEnum.Unverified && realnameStatus.data.onWayApplyStatus === OnWayApplyStatusEnum.Rejected) || 
            (realnameStatus.data.realStatus === RealStatusEnum.Expired && realnameStatus.data.onWayApplyStatus === OnWayApplyStatusEnum.Rejected || !realnameStatus.data.onWayApplyStatus))) {
          modal.plain({
            title: tr('accountOpen.NoRealName.title'),
            description: tr('accountOpen.NoRealName.desc'),
            okText: tr('homePage.recertification'),
            cancelText: tr('page.notYet'),
            imgUrl: 'shield',
            onCancelAfter: () => {
              if (!cancelTopJump) return;
              if (!from || !from.name) {
                // 直接打开页面，返回首页
                goTo("/")
              } else {
                // 从上一页点进来，返回上一页
                historyPush(fromPath)
              }
            },
            onOk: () => {
              goTo("/entauth")
            }
          })
          return false
        } else {
          modal.plain({
            title: tr('accountOpen.NoRealName.title'),
            description: tr('accountOpen.NoRealName.desc'),
            okText: tr('homePage.goToVerify'),
            cancelText: tr('page.notYet'),
            imgUrl: 'shield',
            onCancelAfter: () => {
              if (!cancelTopJump) return;
              if (!from || !from.name) {
                // 直接打开页面，返回首页
                goTo("/")
              } else {
                // 从上一页点进来，返回上一页
                historyPush(fromPath)
              }
            },
            onOk: () => {
              goTo("/entauth")
            }
          })
          return false
        }
      }
      // 未设置密码
      if (!checkIsPWDSetted(status)) {
        const { from, to } = routeInfo || {};
        const fromPath = (!from || checkInInterceptorRoutes(from)) ? '/' : from.fullPath;
        if (routeInfo && !checkInInterceptorRoutes(to)) return false; // 存在路由信息,再拦截路由那里用到该方法并且最新的路由没在拦截路由范围内就不用弹出框了
        modal.plain({
          title: tr('accountOpen.NoPassword.title'),
          description: tr('homePage.setPassword.desc'),
          okText: tr('homePage.goToSetting'),
          cancelText: tr('page.notYet'),
          imgUrl: 'password',
          onCancelAfter: () => {
            if (!cancelTopJump) return;
            if (!from || !from.name) {
              // 直接打开页面，返回首页
              goTo("/")
            } else {
              // 从上一页点进来，返回上一页
              historyPush(fromPath)
            }
          },
          onOk: () => {
            goTo("/paypass")
          }
        })
        return false
      }



    //   switch (ret.data) {
    //     case AccountOpeningProgressEnum.NoRealName:
    //       let realnameStatus;
    //       try {
    //         realnameStatus = await queryCompanyInfo();
    //         console.log('realnameStatus',realnameStatus)
    //         if (realnameStatus.code === "PTS700006") { //未实名
    //           realnameStatus = null;
    //         }
    //       } catch (e) {
    //         realnameStatus = null;
    //       }
    //       if (realnameStatus && 
    //           ((realnameStatus.data.realStatus === 10 && realnameStatus.data.onWayApplyStatus === 120) || 
    //           (realnameStatus.data.realStatus === 30 && realnameStatus.data.onWayApplyStatus === 120 || !realnameStatus.data.onWayApplyStatus))) {
    //         modal.plain({
    //           title: tr('accountOpen.NoRealName.title'),
    //           description: tr('accountOpen.NoRealName.desc'),
    //           okText: tr('homePage.recertification'),
    //           cancelText: tr('page.notYet'),
    //           imgUrl: 'shield',
    //           onCancelAfter: () => {
    //             if (!cancelTopJump) return;
    //             if (!from || !from.name) {
    //               // 直接打开页面，返回首页
    //               goTo("/")
    //             } else {
    //               // 从上一页点进来，返回上一页
    //               historyBack()
    //             }
    //           },
    //           onOk: () => {
    //             goTo("/entauth")
    //           }
    //         })
    //         return false
    //       } else {
    //         modal.plain({
    //           title: tr('accountOpen.NoRealName.title'),
    //           description: tr('accountOpen.NoRealName.desc'),
    //           okText: tr('homePage.goToVerify'),
    //           cancelText: tr('page.notYet'),
    //           imgUrl: 'shield',
    //           onCancelAfter: () => {
    //             if (!cancelTopJump) return;
    //             if (!from || !from.name) {
    //               // 直接打开页面，返回首页
    //               goTo("/")
    //             } else {
    //               // 从上一页点进来，返回上一页
    //               historyBack()
    //             }
    //           },
    //           onOk: () => {
    //             goTo("/entauth")
    //           }
    //         })
    //         return false
    //       }
    //     case AccountOpeningProgressEnum.NoPassword:
    //       modal.plain({
    //         title: tr('accountOpen.NoPassword.title'),
    //         description: tr('homePage.setPassword.desc'),
    //         okText: tr('homePage.goToSetting'),
    //         cancelText: tr('page.notYet'),
    //         imgUrl: 'password',
    //         onCancelAfter: () => {
    //           if (!cancelTopJump) return;
    //           if (!from || !from.name) {
    //             // 直接打开页面，返回首页
    //             goTo("/")
    //           } else {
    //             // 从上一页点进来，返回上一页
    //             historyBack()
    //           }
    //         },
    //         onOk: () => {
    //           goTo("/paypass")
    //         }
    //       })
    //       return false
    //     case AccountOpeningProgressEnum.RealNamePass:
    //       modal.plain({
    //         title: tr('assets.accountList.toApplyAccount'),
    //         description: tr('accountOpen.RealNamePass.desc'),
    //         okText: tr('accountOpen.applyNow'),
    //         cancelText: tr('page.notYet'),
    //         imgUrl: 'account',
    //         onCancelAfter: () => {
    //           if (!cancelTopJump) return;
    //           if (!from || !from.name) {
    //             // 直接打开页面，返回首页
    //             goTo("/")
    //           } else {
    //             // 从上一页点进来，返回上一页
    //             historyBack()
    //           }
    //         },
    //         onOk: () => {
    //           goTo("/")
    //         }
    //       })
    //       return false;
    //     case AccountOpeningProgressEnum.Success:
    //       break;
    //     case AccountOpeningProgressEnum.ApplyExceeded:
    //       break;
    //     case AccountOpeningProgressEnum.ApplyFailed:
    //       break;
    //   }
    }
    return true
  } catch (e) {
    return false
  }
}