import {EntryPathEnum} from "../constants/env"
import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router"
import {tr} from "../i18n"
import {
  showAccountOpeningModal,
  showIsAdmin
} from "../services/AccountOpeningModal.ts";
import { AccountOpeningInterceptorRoutes } from "./rules/main.ts";


export function createRouterByEntry(entry: EntryPathEnum, routes: Array<RouteRecordRaw>) {
  const router = createRouter({
    history: createWebHistory(entry),
    routes,
  })

  let loading = false; 
  let lastToFrom: any = {};

  router.afterEach(async (to, from) => {
    if (__DEV__) {
      return Promise.resolve()
    }
    Object.assign(lastToFrom, {
      to,
      from
    })
    if (loading) return;

    if (to.fullPath === from.fullPath) {
      return
    }
    loading = true;
    const isAdmin = await showIsAdmin(to, from)

    if (isAdmin) {
      const { to:lastTo } = lastToFrom;
      // @ts-ignore
      if (lastTo && AccountOpeningInterceptorRoutes.indexOf(lastTo.name) < 0) {
        loading = false;
        return Promise.resolve()
      }
      const res = await showAccountOpeningModal(lastToFrom);
      loading = false;
      return res;
    } else {
      loading = false;
      return false
    }
  })

  router.afterEach((to) => {
    if (to.meta.title) {
      // @ts-ignore
      document.title = tr(to.meta.title)
    }
  })

  return router
}

